import * as React from 'react';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { Entities } from '@inwink/entities/entities';
import { templatedString } from '@inwink/utils/methods/templatedString';
import { getBlocTemplate } from '@@services/itemshelpers';
import { getHomeSpeakers, getSessionSpeakers, getEventThemeSpeakers,
    getSpeakersByIds, getRecommendationPredicate, getJourneySpeakers } from '../data.speakers';
import type { IDynamicBlocProps } from '@@components/dynamicpage/common';
import { TeaserItems } from '@@components/dynamicpage/teaseritems';
import { SortAndFilterItems } from '@@components/dynamicpage/teaseritems.props';
import type { States } from '@@services/services';
import { getUrlSlug } from 'helpers';

import './bloc.teaserspeaker.less';

export interface IBlocSpeakersState {
    speakers: Entities.ISpeaker[];
    itemtemplate: VisualTheme.IItemTemplate;
    loading: boolean;
}

export function blocSpeakersData(page: States.ICurrentPageState, statedata,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bloc: VisualTheme.IBlocContentTemplateBase, state: States.IAppState) {
    const blocdata = statedata;
    const data = state.event.data;

    blocdata.itemtemplate = getBlocTemplate(data, bloc);
    let speakers;
    if (bloc.properties.whitelist && bloc.properties.whitelist.length) {
        speakers = getSpeakersByIds(bloc.properties.whitelist.map((s) => { return { id: s }; }), data);
    } else if (page.context && page.context.entityKind === "Session" && page.context.entity) {
        speakers = getSessionSpeakers(page.context.entity, data);
    } else if (page.context && page.context.entityKind === "EventTheme" && page.context.entity) {
        speakers = getEventThemeSpeakers(page.context.entity, data);
    } else if (page.context && page.context.entityKind === "Journey" && page.context.entity) {
        speakers = getJourneySpeakers(page.context.entity, data);
    } else {
        speakers = getHomeSpeakers(data);
    }

    if (bloc?.properties?.showRecommandations && state?.user?.currentUser) {
        const recommandationsPredicate = getRecommendationPredicate(state.user, data, null, true);
        const recommendedItems = speakers.filter((s) => recommandationsPredicate(s));
        if (recommendedItems?.length) {
            speakers = recommendedItems;
        }
    }
    blocdata.speakers = SortAndFilterItems(state.user, state.i18n, speakers, bloc.properties, true);
}

export class BlocSpeakers extends React.PureComponent<IDynamicBlocProps<IBlocSpeakersState>, any> {
    constructor(props: IDynamicBlocProps<IBlocSpeakersState>) {
        super(props);
        const itemtemplate = props.blocState?.itemtemplate || props.template?.properties?.template;
        this.state = {
            itemtemplate,
            entitytemplate: this.props.event?.data?.fieldtemplates
                && this.props.event.data.fieldtemplates.data.find((f) => f.entityName.toLowerCase() === "person")
        };
    }

    componentDidUpdate(prevProps: IDynamicBlocProps<IBlocSpeakersState>) {
        if (prevProps.blocState !== this.props.blocState) {
            this.setState({ itemtemplate: this.props.blocState?.itemtemplate });
        }
        if (this.props.user?.checked && !prevProps.user?.checked) {
            const newdata = {} as any;
            blocSpeakersData(this.props.page, newdata, this.props.template, {
                event: this.props.event,
                user: this.props.user,
                i18n: this.props.i18n
            } as any);
            this.props.update(newdata);
        }
    }

    getSpeakerUrl = (speaker: Entities.ISpeaker) => {
        if (this.props.template.properties && this.props.template.properties.targetPage) {
            return this.props.urlservice.pageUrl(templatedString(this.props.template.properties.targetPage,
                { ...this.props.datacontext, entity: speaker }));
        }
        const speakerName = `${speaker.firstname || ""} ${speaker.lastname || ""}`.trim();
        return this.props.urlservice.pageUrl(`speaker/${speaker.id}/${getUrlSlug(speakerName)}`);
    };

    render() {
        let link; let
            className = "";
        if (this.props.template && this.props.template.properties && !this.props.template.properties.disableNavigation) {
            link = this.getSpeakerUrl;
        }
        if (!this.props.blocState || !this.state.itemtemplate) {
            return <div className="empty" />;
        }
        if (this.props.blocState?.itemtemplate && (this.props.blocState.itemtemplate as any).customCSS === undefined) {
            className = "defaultspeakeritem";
        }

        return <TeaserItems
            {...this.props}
            className={className}
            entitySchema={this.state.entitytemplate}
            defaultItemsAlign="center"
            templateEditable={false}
            templateProperties={this.props.template?.properties?.templateProperties}
            allowEmpty={this.props.template?.properties?.allowEmpty}
            emptyMessage={this.props.template?.properties?.emptyMessage}
            minCarouselItems={this.props.template?.properties?.minCarouselItems || null}
            items={this.props.blocState ? this.props.blocState.speakers : null}
            itemClassName="speaker-item"
            linkto={link}
            itemtemplate={this.state.itemtemplate}
        />;
    }
}
